@media screen and (max-width: 812px) {
  /* HUMBERGER MENU */
  /* Position and sizing of burger button */
  .bm-burger-button {
    position: fixed;
    width: 25px;
    height: 20px;
    right: 16px;
    top: 18px;
  }

  /* Color/shape of burger icon bars */
  .bm-burger-bars {
    background: #5c1c50;
  }

  /* Color/shape of burger icon bars on hover*/
  .bm-burger-bars-hover {
    background: #454a56;
  }

  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    height: 24px;
    width: 24px;
  }

  /* Color/shape of close button cross */
  .bm-cross {
    background: white;
  }

  /*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
  .bm-menu-wrap {
    position: sticky;
    top: 0;
    height: 100%;
  }

  /* General sidebar styles */
  .bm-menu {
    background: #3d1435;
    padding: 2.5em 0.5em 0;
    font-size: 1em;
    transition: all 0.5s;
    height: 100vh;
    overflow-y: hidden;
  }

  .bm-menu:hover {
    transition: all 0.5s;
    cursor: pointer;
    color: #cab0c5;
  }

  /* Morph shape necessary with bubble or elastic */
  .bm-morph-shape {
    fill: #3d1435;
  }

  /* Wrapper for item list */
  .bm-item-list {
    color: white;
    padding: 0.8em;
  }

  /* Individual item */
  .bm-item a {
    display: inline-block;
    text-decoration: none;
    outline: none;
    border: none;
    font-size: 1rem;
    margin: 1.4rem;
    color: #ffffff;
  }

  .bm-item a:focus {
    outline: none;
    text-decoration: none;
  }

  /* Styling of overlay */
  .bm-overlay {
    background: rgb(61, 20, 53, 0.3);
  }

  .navLogo {
    z-index: -1;
    width: 40%;
    overflow: hidden;
  }
  .navLogo img {
    width: 4rem;
  }
  .navLogo a:focus {
    outline: none;
    background-color: none;
  }

  /* HOME */
  .homeContainer > div {
    margin: 0 1rem 0 1rem;
  }
  .landingBox {
    display: grid;
    grid-template: 1fr / 1fr;
    background-image: unset;
    border-radius: 38% 62% 58% 42% / 44% 48% 52% 56%;
    background-color: #fff6fd;
  }

  .landingBox > div:first-child {
    background-size: contain;
    width: 100%;
    background-image: none;
    padding: unset;
  }

  .landingBox > div:first-child h4 {
    font-size: 1.5rem;
    text-align: center;
    color: #1c1c1c;
  }
  .landingBox > div:first-child p {
    text-align: center;
    width: 100%;
    color: #1c1c1c;
  }
  .landingButtonBox {
    /* width: 100%; */
    text-align: center;
  }
  .landingButton {
    color: #1c1c1c;
    border: 0.1rem solid #4e1944;
  }
  .landingButton:hover {
    border: 0.1rem dashed #4e1944;
  }
  .featuresSection {
    display: grid;
    grid-template: 1fr / 1fr;
    /* padding: unset; */
  }

  .featuresSection > div:first-child {
    padding: unset;
  }
  .featuresSection > div:first-child h4 {
    font-size: 1.6rem;
    letter-spacing: 1px;
    line-height: 1.4;
    width: unset;
    margin-bottom: 0.5rem;
    text-align: center;
  }
  .featuresSection > div:first-child p {
    font-size: 1rem;
    letter-spacing: 1px;
    line-height: 1.6;
    width: unset;
    margin-bottom: 0.5rem;
    text-align: center;
  }
  .featuresSection > div:last-child {
    padding: unset;
    margin-top: 1rem;
    background-image: none;
  }
  .featuresSection > div:last-child > div h5 {
    font-size: 1rem;
  }
  .featuresSection > div:last-child > div p {
    font-size: 0.9rem;
    width: 100%;
    text-align: center;
    margin: 1rem auto;
  }
  .featuresSection > div:last-child > div > div:first-child {
    text-align: center;
    display: unset;
  }
  .mockupSection {
    display: grid;
    grid-template: 1fr / 1fr;
    /* padding: unset; */
    padding: 2rem 0 2rem 0;
    background-color: rgb(61, 20, 53, 0.05);
    border-radius: 0.3rem;
  }
  .phoneIcon {
    width: 100%;
    z-index: -1;
  }

  .mockupSection > div:last-child {
    background-image: none;
  }
  .mockupSection > div:last-child h4,
  .statsSection > div:last-child h4,
  .fixtureSection > div:first-child h4 {
    font-size: 1.6rem;
    width: unset;
    text-align: center;
    font-weight: bold;
  }
  .mockupSection > div:last-child p,
  .statsSection > div:last-child p,
  .fixtureSection > div:first-child p {
    font-size: 1rem;
    width: unset;
    margin-bottom: 0.5rem;
    text-align: center;
  }

  footer {
    height: unset;
    width: 100%;
    background-image: url("./assets/images/footBubbleMob.svg");
    background-repeat: no-repeat;
    background-size: cover;
    padding-bottom: 0.2rem;
  }

  footer > div:nth-child(2) {
    margin-top: 1rem;
  }

  footer > div:nth-child(3) {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .storeIcon {
    font-size: 2.3rem;
  }

  .socialIcon {
    font-size: 2rem;
  }

  footer .store__link > div {
    display: flex;
    align-items: center;
    background-color: whitesmoke;
    border: 0.2rem solid #929292;
    padding: 0.2rem;
    border-radius: 0.5rem;
    width: 45%;
    margin: 0 auto;
  }

  footer .store__link > div p {
    font-size: 0.7rem;
    letter-spacing: 0.5px;
    text-transform: uppercase;
  }

  footer .store__link > div h4,
  footer .store__link > div h4 {
    font-size: 1rem;
    letter-spacing: 1px;
    text-transform: uppercase;
  }

  .statsSection {
    display: grid;
    grid-template: 1fr / 1fr;
    height: unset;
    padding: unset;
    /* margin: 2rem 0 2rem 0; */
    padding: 3rem 0 3rem 0;
  }
  .playerIcon {
    font-size: 10rem;
    color: #5c1c50;
  }
  .fixtureSection,
  .mockupSection {
    display: flex;
    flex-direction: column-reverse;
    height: unset;
    padding: unset;
    /* margin: 2rem 0 2rem 0; */
    padding: 3rem 1rem 3rem 1rem;
    background-color: rgba(175, 26, 145, 0.05);
  }
  .calendarIcon {
    font-size: 8rem;
    color: #5c1c50;
  }
  .curveLeft {
    position: absolute;
    top: 50%;
    left: 0;
    width: 10rem;
    object-fit: contain;
    opacity: 0.05;
  }
  .curveRighBub {
    position: absolute;
    top: 80%;
    right: 0;
    width: 10rem;
    object-fit: contain;
    opacity: 0.05;
    transform: rotate(180deg);
  }
  .curveRight {
    position: absolute;
    top: 30%;
    right: 0;
    width: 10rem;
    object-fit: contain;
    opacity: 0.1;
  }

  .promotion__landing {
    /* height: 80vh; */
  }

  .promotion__landing > div h2 {
    font-size: 2rem;
  }

  .text__input_box {
    width: 90%;
  }

  .button__box button {
    width: 90%;
  }

  .fantacyPrizeContainer {
    margin: 0.2rem;
    padding: 1rem;
    border-radius: 1rem;
  }

  .fantansyPodcastContainer {
    font-family: "Poppins-Medium", sans-serif;
    font-size: 20px;
    margin: 5rem 1rem 1rem 1rem;
    padding: 1rem;
  }

  .rulesContainer {
    font-family: "Poppins-Medium", sans-serif;
    margin: 0.3rem;
    padding: 0.5rem;
  }


  .rulesAnswerBox p {
    color: black;
    font-size: 0.9rem;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1024px) {
  /* HUMBERGER MENU */
  /* Position and sizing of burger button */
  .bm-burger-button {
    position: fixed;
    width: 25px;
    height: 20px;
    right: 16px;
    top: 18px;
  }

  /* Color/shape of burger icon bars */
  .bm-burger-bars {
    background: #5c1c50;
  }

  /* Color/shape of burger icon bars on hover*/
  .bm-burger-bars-hover {
    background: #454a56;
  }

  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    height: 24px;
    width: 24px;
  }

  /* Color/shape of close button cross */
  .bm-cross {
    background: white;
  }

  /*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
  .bm-menu-wrap {
    position: sticky;
    top: 0;
    height: 100%;
  }

  /* General sidebar styles */
  .bm-menu {
    background: #3d1435;
    padding: 2.5em 0.5em 0;
    font-size: 1em;
    transition: all 0.5s;
    height: 100vh;
    overflow-y: hidden;
  }

  .bm-menu:hover {
    transition: all 0.5s;
    cursor: pointer;
    color: #cab0c5;
  }

  /* Morph shape necessary with bubble or elastic */
  .bm-morph-shape {
    fill: #3d1435;
  }

  /* Wrapper for item list */
  .bm-item-list {
    color: white;
    padding: 0.8em;
  }

  /* Individual item */
  .bm-item a {
    display: inline-block;
    text-decoration: none;
    outline: none;
    border: none;
    font-size: 1rem;
    margin: 1.4rem;
    color: #ffffff;
  }

  .bm-item a:focus {
    outline: none;
    text-decoration: none;
  }

  /* Styling of overlay */
  .bm-overlay {
    background: rgb(61, 20, 53, 0.3);
  }

  .navLogo {
    z-index: -1;
    width: 40%;
    overflow: hidden;
  }
  .navLogo img {
    width: 4rem;
  }
  .navLogo a:focus {
    outline: none;
    background-color: none;
  }
  .promotion__landing {
    min-height: 30vh;
  }
}
