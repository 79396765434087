
*::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
* {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.appBody {
  position: relative;
}

.appContainer {
  width: 100%;
  min-height: 100vh;
  position: relative;
}

.navBarContainer {
  position: sticky;
  top: 0;
  z-index: 1000;
}

.navBarBox {
  height: 10vh;
  background-image: linear-gradient(to bottom right, rgb(255, 255, 255, 0.8) 0%, #610E61 49%, #510051 100%);
  width: 100%;
  display: flex;
  justify-content: space-around;
}

.navBarBox nav {
  /* background-color: bisque; */
  float: right;
  display: flex;
  /* background-color: white; */
  height: 10vh;
  padding: 0 1rem 0 0;
}

.downloadButtonBox {
  display: grid;
  place-items: center;
}

.downloadApplePlayStore {
  display: flex;
}

.downloadApplePlayStore > div {
  margin: 0 0.5rem 0 0.5rem;
}

.downloadButton {
  background-color: transparent;
  border-radius: 2rem;
  border: 0.2rem solid #8f467f;
  padding: 0.3rem;
  font-size: 0.95rem;
  font-family: "Nunito-Bold", sans-serif;
  width: auto;
  transition: all 0.5s;
}

.downloadButton:hover {
  transition: all 0.5s;
  transform: scale(1.02);
  cursor: pointer;
  border: 0.2rem solid #b878aa;
}

.navBarBox nav ul {
  list-style-type: none;
  margin-top: 0.65rem;
  display: flex;
  margin-right: 2rem;
}

.navBarBox nav ul li {
  display: inline-block;
  line-height: 50px;
  margin: 0 20px;
  text-transform: capitalize;
  font-size: 1rem;
  font-weight: bold;
  transition: all 0.5s;
}

.navBarBox nav ul li a {
  text-decoration: none;
  color: white;
}

.navBarBox nav ul li:hover {
  cursor: pointer;
  color: #a0209a;
  transition: all 0.5s;
}

.appBody {
  min-height: 100vh;
  background-color: #ffffff;
  padding-bottom: 2rem;
  /* padding: 2rem; */
}

.navBarLogoBox {
  display: grid;
  place-items: center;
  /* background-color: bisque; */
  z-index: 2000;
}

.navBarLogoBox img {
  width: 12%;
  object-fit: contain;
  z-index: 5000;
}

.navBarLogoBox img:hover {
  cursor: pointer;
}

/* FOOTER */
footer {
  width: 100%;
  /* height: 50vh; */
  background: white;
  padding: 1rem 0 1rem 0;
  background-image: url("./assets/images/footbubble.svg");
  background-repeat: no-repeat;
  background-size: cover;
}

footer > div:first-child {
  text-align: center;
  display: grid;
  place-items: center;
  background-image: url("./assets/images/dots.svg");
  background-repeat: no-repeat;
  background-size: cover;
}

footer > div:first-child > div {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: white;
}

footer > div:first-child img {
  width: 5rem;
  object-fit: contain;
}

footer > div:first-child h4 {
  font-family: "Nunito-Bold", sans-serif;
  font-size: 1.6rem;
  color: #ffffff;
  margin-top: 0.5rem;
}

footer > div:nth-child(2) {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3rem;
}

.socialIcon {
  font-size: 3rem;
  color: white;
  margin: 0 0.5rem 0 0.5rem;
  transition: all 0.5s;
}

.soccerFieldIcon {
  color: #fcf6fc;
  background-color: white;
  font-size: 10rem;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  /* padding: 1rem; */
}

.socialIcon:hover,
.chevronScrollUpIcon {
  cursor: pointer;
  transition: all 0.5s;
  transform: scale(1.1);
}

.chevronScrollUpIcon {
  position: absolute;
  top: 0;
  right: 1%;
  color: #e4b8db;
  font-size: 2.7rem;
  transition: all 0.5s;
  -webkit-animation-name: bounce;
  animation-name: bounce;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
}

@-webkit-keyframes bounce {
  0%,
  100% {
    -webkit-transform: translateY(0);
    opacity: 0.6;
  }
  50% {
    -webkit-transform: translateY(40px);
    opacity: 1;
  }
}

@keyframes bounce {
  0%,
  100% {
    transform: translateY(0);
    opacity: 0.6;
  }
  50% {
    transform: translateY(-5px);
    opacity: 1;
  }
}

footer > div:nth-child(3) {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
  position: relative;
}

footer > div:nth-child(3) a {
  line-height: 50px;
  margin: 0 20px;
  text-transform: capitalize;
  font-size: 1rem;
  font-weight: bold;
  color: #fff4fd;
  text-decoration: none;
  transition: all .5s;
}

footer > div:nth-child(3) a:hover {
  cursor: pointer;
  transition: all .5s;
  color: #e4c5e2;
}

footer .copyright p {
  text-align: center;
  color: white;
  margin-top: 1rem;
  font-size: 0.9rem;
}

footer .store__links {
  display: grid;
  grid-template: 1fr / 1fr 1fr;
  grid-gap: 2rem;
  width: 50%;
  margin: 0 auto;
}

.storeIcon {
  font-size: 3rem;
}

footer .store__links > div:first-child,
footer .store__links > div:last-child {
  display: flex;
  align-items: center;
  background-color: whitesmoke;
  border: 0.2rem solid #929292;
  padding: 0.5rem;
  border-radius: 1rem;
  transition: all .5s;
}

footer .store__links > div:first-child:hover,
footer .store__links > div:last-child:hover {
  cursor: pointer;
  transform: scale(1.01);
  transition: all .5s;
  border: 0.2rem solid #a0209a;
  background-color: transparent;
  color: whitesmoke;
}

footer .store__links > div:first-child > div:first-child,
footer .store__links > div:last-child > div:first-child {
  margin-right: 1rem;
}

footer .store__links > div:first-child p,
footer .store__links > div:last-child p {
  font-size: 1rem;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}

footer .store__links > div:first-child h4,
footer .store__links > div:last-child h4 {
  font-size: 1.8rem;
  letter-spacing: 1px;
  text-transform: uppercase;
}



/* ------HOME-------- */
.homeContainer {
  width: 100%;
  position: relative; 
  overflow: hidden;
}

.landingBox {
  height: 80vh;
  display: flex;
  justify-content: space-between;
  padding: 3rem;
  background-image: url("./assets/images/featureGraphic.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.landingBox > div:first-child {
  display: grid;
  place-items: center;
  background-image: url("./assets/images/oval.svg");
  background-repeat: no-repeat;
  background-size: contain;
  padding: 2rem;
  /* width: 70%; */
}

.landingBox > div:first-child h4 {
  font-size: 2.5rem;
  font-family: "Nunito-Bold", sans-serif;
  color: #ffffff;
  letter-spacing: 1px;
}

.landingBox > div:first-child p {
  font-size: 0.9rem;
  font-family: "Nunito-Light", sans-serif;
  color: #ffffff;
  letter-spacing: 1px;
  /* text-align: center; */
  width: 70%;
  margin-top: 1rem;
  line-height: 1.4;
}

.landingButtonBox {
  width: 70%;
  margin: 0 auto;
}

.landingButton {
  background-color: transparent;
  border-radius: 2rem;
  border: 0.2rem solid #ffffff;
  padding: 1rem;
  font-size: 0.95rem;
  font-family: "Nunito-Bold", sans-serif;
  width: auto;
  transition: all 0.5s;
  color: white;
  margin-top: 2rem;
}

.landingButton:hover {
  transition: all 0.5s;
  transform: scale(1.1);
  cursor: pointer;
  border: 0.2rem dashed #4e1944;
  padding: 1rem;
}

.landingBox > div:last-child {
  display: grid;
  place-items: center;
  padding: 2rem;
}

/* FEATURES */
.featuresSection {
  display: grid;
  grid-template: 1fr / 1fr 1fr;
  grid-gap: 0.5rem;
  padding: 3rem;
}

.featuresSection > div:first-child {
  display: grid;
  place-items: center;
  padding: 2rem;
  background-image: url("./assets/images/dots2.svg");
  background-repeat: no-repeat;
  background-size: contain;
}

.featuresSection > div:first-child h4 {
  font-size: 2.4rem;
  letter-spacing: 1px;
  line-height: 1.4;
  width: 80%;
  margin-bottom: 1rem;
}

.featuresSection > div:first-child p {
  font-size: 1.1rem;
  letter-spacing: 1px;
  line-height: 1.6;
  width: 80%;
  margin-bottom: 1rem;
}

.featuresSection > div:last-child {
  display: grid;
  place-items: center;
  padding: 2rem;
  background-image: url("./assets/images/dotCurve.svg");
  background-repeat: no-repeat;
  background-size: contain;
}

.featuresSection > div:last-child > div {
  margin-bottom: 1rem;
}

.featuresSection > div:last-child > div h5 {
  font-size: 1.1rem;
  font-family: "Poppins-Medium", sans-serif;
  color: #1c1c1c;
  letter-spacing: 1px;
  margin-left: 0.5rem;
}

.featuresSection > div:last-child > div p {
  font-size: 1rem;
  font-family: "Poppins-Light", sans-serif;
  color: #1c1c1c;
  letter-spacing: 1px;
  margin-top: 0.5rem;
  margin-left: 1.8rem;
  width: 80%;
  line-height: 1.6;
}

.featuresSection > div:last-child > div > div:first-child {
  display: flex;
}

/* STATS */
.statsSection {
  display: grid;
  grid-template: 1fr / 1fr 1fr;
  grid-gap: 0.5rem;
  height: 90vh;
  padding: 3rem;
}

.statsSection > div:first-child {
  display: grid;
  place-items: center;
  text-align: center;
  background-image: url("./assets/images/dotsCurve2.svg");
  background-repeat: no-repeat;
  background-size: contain;
}

.playerIcon {
  font-size: 20rem;
  color: #5c1c50;
}

.statsSection > div:last-child {
  display: grid;
  place-items: center;
}

.fixtureSection {
  display: grid;
  grid-template: 1fr / 1fr 1fr;
  grid-gap: 0.5rem;
  height: 90vh;
  padding: 3rem;
}

.fixtureSection > div:last-child {
  display: grid;
  place-items: center;
  text-align: center;
  background-image: url("./assets/images/dots2.svg");
  background-repeat: no-repeat;
  background-size: contain;
}

.calendarIcon {
  font-size: 20rem;
  color: #5c1c50;
}

.fixtureSection > div:first-child {
  display: grid;
  place-items: center;
}


/*  MOCKUP */
.mockupSection {
  display: grid;
  grid-template: 1fr / 1fr 1fr;
  grid-gap: 0.5rem;
  padding: 3rem;
  /* height: 100vh; */
}

.mockupSection > div:first-child {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
}

/* .phoneIcon {
  font-size: 40rem;
  filter: invert(0);
  color: #3D1435;
} */

.phoneIcon {
  width: 110%;
  object-fit: contain;
  /* float: right; */
}

.mockupSection > div:last-child {
  display: grid;
  place-items: center;
  background-image: url("./assets/images/dots2.svg");
  background-repeat: no-repeat;
  background-size: contain;
}

.mockupSection > div:last-child h4,
.statsSection > div:last-child h4,
.fixtureSection > div:first-child h4  {
  font-size: 2.4rem;
  letter-spacing: 1px;
  line-height: 1.4;
  width: 80%;
  margin-bottom: 1rem;
}

.mockupSection > div:last-child p,
.statsSection > div:last-child p,
.fixtureSection > div:first-child p {
  font-size: 1.1rem;
  letter-spacing: 1px;
  line-height: 1.6;
  width: 70%;
  margin-bottom: 1rem;
}
.curveLeft {
  position: absolute;
  top: 50%;
  left: 0;
  width: 20rem;
  object-fit: contain;
  opacity: .05;
}
.curveRighBub {
  position: absolute;
  top: 80%;
  right: 0;
  width: 20rem;
  object-fit: contain;
  opacity: .05;
  transform: rotate(180deg);
}
.curveRight {
  position: absolute;
  top: 30%;
  right: 0;
  width: 10rem;
  object-fit: contain;
  opacity: .1;
}

/* promotion */
.promotion__box {
  width: 100%;
  /* min-height: 100vh; */
}

.promotion__landing {
  height: 200px;
  overflow: hidden;
  display: grid;
  place-items: center;
  text-align: center;
  /* background-color: rgb(160, 32, 154, 0.2); */
  background-image: linear-gradient(
    to bottom right,
    rgb(255, 255, 255, 0.8) 0%,
    #610e61 49%,
    #510051 100%
  );
}

.promotion__landing > div h2 {
  font-size: 3rem;
  color: whitesmoke;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-style: italic;
}

.promotion__form_box {
  background-image: linear-gradient(
    to top right,
    rgba(255, 255, 255, 1) 15%,
    rgb(97, 14, 97, 0.7) 49%,
    rgb(81, 0, 81, 0.8) 100%
  );
  padding: 2rem 0 2rem 0;
}

.promotion__form_box h2 {
  transform: scale(1.08);
  text-align: center;
  color: #ffffff;
}

.text__input_box {
  width: 50%;
  margin: 0 auto;
  background-color: #fff6fd;
  border-radius: 3px;
  padding: 2rem;
  box-shadow: 0 1px 3px rgba(219, 57, 224, 0.12),
    0 1px 2px rgba(110, 0, 110, 0.24);
}

.text__input_box input {
  width: 90%;
  height: 23px;
  font-size: 16px;
  letter-spacing: 2px;
  padding: 1rem;
  background: linear-gradient(
    91.37deg,
    #fffbff 16.39%,
    rgba(255, 196, 250, 0) 71.47%
  );
  border: 2px solid #cfcfcf;
  border-radius: 3px;
  outline: none;
  transition: all 0.3s;
}

.text__input_box input:focus {
  transition: all 0.3s;
  border: 2px solid #7b1478;
}

.inputStyle {
  border: 2px solid #cfcfcf;
  border-radius: 3px;
  outline: none;
}

.inputStyle:active {
  outline: 0;

}

.inputStyle:hover {
  transition: all 0.3s;
  border: 2px solid #7b1478;

}

.text__select_box select {
  outline: none;
  box-shadow: none;
}





.button__box {
  margin-top: 2rem;
  margin-left: 16rem;
  line-height: 1.2rem;

}

/* button */
.button__box button {
  /* height: 69px; */
  padding: 0.5rem;
  font-size: 1rem;
  font-weight: 300;
  font-size: 1.2rem;
  text-transform: uppercase;
  letter-spacing: 2px;
  border: 2px solid #7b1478;
  box-sizing: border-box;
  border-radius: 3px;
  background-color: transparent;
  transition: all 0.3s;
}

.button__box button:hover {
  cursor: pointer;
  transition: all 0.3s;
  transform: scale(1.02);
  background-color: #510250;
  color: white;
}

.inputLabelStyle,
.text__input_box p {
  font-size: 1.2rem;
  letter-spacing: 2px;
  line-height: 1.6;
  margin-top: 0.5rem;
  color: black;
  border-radius: 1rem;


}

.viewOnMobile {
  width: 100%;
  height: 100vh;
  display: grid;
  place-items: center;
  background-image: linear-gradient(
    to top right,
    rgba(255, 255, 255, 1) 10%,
    rgb(97, 14, 97, 0.7) 49%,
    rgb(81, 0, 81, 0.8) 100%
  );
}

.viewOnMobile h2 {
  color: white;
  text-align: center;
}

.viewPromoContainer {
  display: grid;
  grid-template: 1fr / 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 2rem;
  margin: 2rem;
}

.promoContainer {
  background-color: #1c1c1c;
}

.promoContainer h2, p {
  color: white;
}


/* ------------------------------------------------- ABOUT ------------------------------------------------------ */

.about {
  margin: 2rem;
}

.about p {
  color: #fff;
}

.about section {
  background-color: #3d1435;
  padding: 1rem;
  margin: 0.5rem
}

/*---------------------------------------- FANTASY ----------------------------------------------------------------*/
.fantacyPrizeContainer{
  background-color: rgb(81,0,81);
  font-family: "Poppins-Medium", sans-serif;
  color: white;
  margin: 5rem;
  padding: 5rem;
  border-radius: 4.8rem;
}

/*rgb(97, 14, 97, 0.7) 49%,*/
/*rgb(81, 0, 81, 0.8)*/

.fantacyPrizeContainer {
  color: white;
  font-family: "Poppins-Medium", sans-serif;
}

#fantasyTitle {
  color: white;
  font-family: "Poppins-Medium", sans-serif;
}

.fantansyPodcastContainer {
  font-family: "Poppins-Medium", sans-serif;
  font-size: 20px;
  margin: 10rem;
  padding: 10rem;
}

.fantasyTermsAndConditionsContainer {
  padding: 2em 3em;
  font-family: "Poppins-Medium", sans-serif;
  font-size: 20px;
  margin: 2rem;
}

.fantasyTermsAndConditionsContainer p {
  color: black;
  font-size: 16px;
}

.rulesContainer {
  font-family: "Poppins-Medium", sans-serif;
  margin: 2rem;
  padding: 2rem;
}

.rulesAnswerBox p {
  color: black;
  font-size: 13.5px;
}

.rulesCollapsePanel:hover {
  color: black;
  font-size: 16px;
  background-image: linear-gradient(to bottom right, rgb(255, 255, 255, 0.5) 0%, #610E61 80%, #510051 30%);;


}

.collapseItem h2 {
  margin-top: 1rem;
}


/*-----------scoring-------------*/
.scoringContainer p{
  font-family: "Poppins-Medium", sans-serif;
  font-size: 15px;
}
.columnScore {
  display: grid;
  grid-template: 1fr /1fr 1fr;
  border-bottom: 1px solid black;
}

.rowScore {
  display: grid;
  grid-template: 1fr /1fr 1fr;
  border-bottom: 1px solid black;
  
}

.columnLeagueScore {
  display: grid;
  grid-template: 1fr /1fr 1fr 1fr;
  border-bottom: 1px solid black;
}

.rowLeagueScore {
  display: grid;
  grid-template: 1fr /1fr 1fr 1fr;
  border-bottom: 1px solid black;
}
